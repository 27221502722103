<template>
  <div>
    <h4>CAJAS REGISTRADORAS</h4>
    <app-table-registers
      ref="tableRegisters"
      :getList="getList"
      @btnNew="
        $refs.dFormCashRegister.show();
        $refs.cbForm.reset();
      "
    >
      <template slot="tl">
        <div class="col form-inline">
          <button
            @click="
              $refs.dFormCashRegister.show();
              $refs.cbForm.reset();
            "
            class="btn btn-primary mr-1"
          >
            <i class="fa fa-plus"></i> Nuevo
          </button>
          <SelectLockedState v-model="locked_status"></SelectLockedState>
          <SelectEntity
            v-model="entity_id"
            @input="$refs.tableRegisters.gl()"
          ></SelectEntity>
        </div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Area</th>
            <th>Estado</th>
            <th>Responsable</th>
            <th>Ingresos</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.id }}</td>
            <td>{{ l.created_at | dateTimeFormat }}</td>
            <td>{{ l.area_name }}</td>
            <td>
              <template v-if="l.locked == 0">
                <i class="fa fa-unlock"></i>
              </template>
              <template v-else>
                <i class="fa fa-lock"></i>
              </template>
            </td>
            <td>{{ l.user_name }}</td>
            <td>
              <span v-if="l.sum_inputs.length > 0">
                <select class="custom-select custom-select-sm">
                  <option value v-for="l1 in l.sum_inputs" :key="l1.quantity">
                    <app-span-money
                      :quantity="l1.quantity"
                      :moneyCode="l1.money_code"
                    ></app-span-money>
                  </option>
                </select>
              </span>
            </td>

            <td class="text-right">
              <router-link
                :to="'/admin/cashregisters/' + l.id"
                class="btn btn-light btn-sm"
                title="Editar"
              >
                <i class="fa fa-archive"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic idModal="dFormCashRegister" ref="dFormCashRegister">
      <h4>FORMULARIO DE CAJA REGISTRADORA</h4>
      <cbForm
        ref="cbForm"
        @submitted="
          $refs.dFormCashRegister.hide();
          $refs.tableRegisters.gl();
        "
      ></cbForm>
    </app-modal-basic>
  </div>
</template>

<script>
import Form from "./Form";
import SelectLockedState from "./SelectLockedState";
import { AdminService } from "../AdminService";
import SelectEntity from "../entitys/Select";

export default {
  components: {
    cbForm: Form,
    SelectLockedState,
    SelectEntity
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: {},
    locked_status: undefined,
    entity_id: 0
  }),

  computed: {
    //
  },

  watch: {
    locked_status() {
      this.$refs.tableRegisters.gl();
    }
  },

  created() {
    this.entity_id = this.$store.state.user.entity_id;
  },

  mounted() {
    //
  },

  methods: {
    getList(params) {
      return new Promise((rsv) => {
        AdminService.getCashRegisters({
          ...params,
          locked: this.locked_status,
          entity_id: this.entity_id
        }).then((res) => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
